//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UMUM from "../../library/umum";
export default {
  data() {
    return {


      form : {
        id : '',
        pk_unit_sasaran : '',
        pk_unit_sasaran_uraian : '',
        pk_unit_sasaran_indikator : '',
        pk_unit_sasaran_indikator_uraian : '',
        pk_unit_sasaran_indikator_target : '',
        pk_unit_sasaran_indikator_satuan : '',
        pk_unit_sasaran_indikator_uraian_kuantitas : '',
        pk_unit_sasaran_indikator_target_kuantitas : '',
        pk_unit_sasaran_indikator_satuan_kuantitas : '',
        pk_unit_sasaran_indikator_uraian_waktu : '',
        pk_unit_sasaran_indikator_target_waktu : '',
        pk_unit_sasaran_indikator_satuan_waktu : '',

        ra_target_indikator : '',
        ra_target_indikator_bln_ini : '',

        ku_ra_strategis_indikator : '',
        ku_ra_strategis_indikator_capaian : 0,
        pk_unit_sasaran_indikator_capaian : 0,
        hari : '',
        bulan : '',
        tahun : '',
        periode_id : '',
        worksheet_uraian : '',
        worksheet_capaian : 0,
        worksheet_satuan : '',
        worksheet_lokasi : 'Kantor',
        keterangan : '-',
        nip : '',
        nip_verifikator : '',
        status : '',
        keterangan_status : '',
        file : [],

        delegasi : '',

        tglFull : ''
      },

      text : {
        sasaran_uraian : '',
        indikator_uraian : '',
        sasaran_tahun : '',
        indikator_target : '',
        indikator_sat : '',
        ra_uraian_indikator : '',
        ra_uraian_indikator_satuan : '',
      },


      lampiran : {
        id : '',
        file : '',
        type : '',
        ref : '',
      },

      id : '',
      jns_golongan_id : '',
      list_sasaran_strategis : [],
      list_data : [],
      list_worksheet : [],
      list_lampiran : [],


      page_first: 1,
      page_last: 0,
      page_limit : 10,
      cari_value: "",
      cek_load_data : false,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_chat : false,
      mdl_maps : false,
      btn_add: false,
      mdl_ket : false,
      mdl_list_lamp : false,
      mdl_lamp : false,

      mdl_list : false,
      mdl_maps_tambah : false,
      mdl_img : false,
      mdl_lihat_data : false,


      marginFilter : '',

      UMUM : UMUM,
    }
  },
  methods: {


    getData : function(){
      this.cek_load_data = true;
      fetch(this.$store.state.url.URL_kinerja_ku_ra_strategis + "view", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              tahun: this.form.tahun,
              data_ke: this.page_first,
              cari_value: this.cari_value,
              delegasi : this.form.delegasi,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data;
              this.cek_load_data = false;
              console.log(res_data);
      });
    },


    addData : async function() {

      // fetch(this.$store.state.url.URL_kinerja_worksheet + "addData", {
      //     method: "POST",
      //     headers: {
      //       "content-type": "application/json",
      //       authorization: "kikensbatara " + localStorage.token
      //     },
      //     body: JSON.stringify(this.form)
      // }).then(res_data => {
      //     this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
      //     this.getDataWorksheet()
      // });

      await this.ReConvertFormatDate(this.form.tglFull)

      var formData = new FormData();
      formData.append('id', this.form.id);
      formData.append('pk_unit_sasaran', this.form.pk_unit_sasaran);
      formData.append('pk_unit_sasaran_uraian', this.form.pk_unit_sasaran_uraian);
      formData.append('pk_unit_sasaran_indikator', this.form.pk_unit_sasaran_indikator);
      formData.append('pk_unit_sasaran_indikator_uraian', this.form.pk_unit_sasaran_indikator_uraian);
      formData.append('pk_unit_sasaran_indikator_target', this.form.pk_unit_sasaran_indikator_target);
      formData.append('pk_unit_sasaran_indikator_satuan', this.form.pk_unit_sasaran_indikator_satuan);

      formData.append('ra_target_indikator', this.form.ra_target_indikator);
      formData.append('ra_target_indikator_bln_ini', this.form.ra_target_indikator_bln_ini);

      formData.append('ku_ra_strategis_indikator', this.form.ku_ra_strategis_indikator);
      formData.append('ku_ra_strategis_indikator_capaian', this.form.ku_ra_strategis_indikator_capaian);
      formData.append('pk_unit_sasaran_indikator_capaian', this.form.pk_unit_sasaran_indikator_capaian);
      formData.append('hari', this.form.hari);
      formData.append('bulan', this.form.bulan);
      formData.append('tahun', this.form.tahun);


      console.log(this.form.hari);
      
      formData.append('periode_id', this.form.periode_id);
      formData.append('worksheet_uraian', this.form.worksheet_uraian);
      formData.append('worksheet_capaian', this.form.worksheet_capaian);
      formData.append('worksheet_satuan', this.form.worksheet_satuan);
      formData.append('worksheet_lokasi', this.form.worksheet_lokasi);
      formData.append('keterangan', this.form.keterangan);
      formData.append('nip', this.form.nip);
      formData.append('nip_verifikator', this.form.nip_verifikator);
      formData.append('status', this.form.status);

      var filex = this.form.file
      filex.forEach(element => {
        // console.log(element)
        formData.append('file', element);
      });

      // formData.append('files', this.form.file);


      // console.log(this.form.file)

      fetch(this.$store.state.url.URL_kinerja_worksheet + "addData", {
          method: "POST",
          headers: {
              authorization : 'kikensbatara '+localStorage.token
          },
          body: formData
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getDataWorksheet()
      });

    },


    editData : function(){

      fetch(this.$store.state.url.URL_kinerja_worksheet + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getDataWorksheet()
      });
    },

    removeData : function(){
      fetch(this.$store.state.url.URL_kinerja_worksheet + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getDataWorksheet()
      });

    },


    getDataWorksheet : function(){
      // this.cek_load_data = true;
      fetch(this.$store.state.url.URL_kinerja_worksheet + "view2", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              tahun: this.form.tahun,
              ku_ra_strategis_indikator  : this.form.ku_ra_strategis_indikator,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_worksheet = res_data;
              console.log(res_data)

      });
    },

    selectData : function(data){


        // console.log(data);

        this.form.id = data.id
        // this.form.pk_unit_sasaran = data.pk_unit_sasaran
        // this.form.pk_unit_sasaran_uraian = data.pk_unit_sasaran_uraian
        // this.form.pk_unit_sasaran_indikator = data.pk_unit_sasaran_indikator
        // this.form.pk_unit_sasaran_indikator_uraian = data.pk_unit_sasaran_indikator_uraian
        // this.form.pk_unit_sasaran_indikator_target = data.pk_unit_sasaran_indikator_target
        // this.form.pk_unit_sasaran_indikator_satuan = data.pk_unit_sasaran_indikator_satuan

        // this.form.ra_target_indikator = data.ra_target_indikator
        // this.form.ra_target_indikator_bln_ini = data.ra_target_indikator_bln_ini

        this.form.ku_ra_strategis_indikator = data.ku_ra_strategis_indikator
        this.form.ku_ra_strategis_indikator_capaian = data.ku_ra_strategis_indikator_capaian
        this.form.pk_unit_sasaran_indikator_capaian = data.pk_unit_sasaran_indikator_capaian
        this.form.periode_id = data.periode_id


        this.form.status = data.status
        this.form.hari = data.hari
        this.form.bulan = data.bulan
        this.form.tahun = data.tahun
        this.form.worksheet_uraian = data.worksheet_uraian
        this.form.worksheet_capaian = data.worksheet_capaian
        this.form.worksheet_satuan = data.worksheet_satuan
        this.form.worksheet_lokasi = data.worksheet_lokasi
        this.form.keterangan = data.keterangan
        this.form.nip = data.nip
        this.form.nip_verifikator = data.nip_verifikator
        this.form.keterangan_status = data.keterangan_status

        this.form.tglFull = this.convertFormatDate(data.tahun+'-'+data.bulan+'-'+data.hari)


    },


    selectDataIndikator : function(data, data1, data2){


      console.log("DATA 1");
      console.log(data)
      console.log("DATA 2");
      console.log(data1)
      console.log("DATA 3");
      console.log(data2)

        this.form.tahun = data.tahun
        this.text.ra_uraian_indikator = data2.target_uraian
        this.text.ra_uraian_indikator_satuan = data2.target_satuan


        this.form.pk_unit_sasaran =data.id;
        this.form.pk_unit_sasaran_uraian =data.uraian;
        this.form.pk_unit_sasaran_indikator =data1.indikator_id;
        this.form.pk_unit_sasaran_indikator_uraian =data1.indikator_uraian;
        this.form.pk_unit_sasaran_indikator_target =data1.indikator_target;
        this.form.pk_unit_sasaran_indikator_satuan =data1.indikator_satuan;

        this.form.pk_unit_sasaran_indikator_uraian_kuantitas = data1.indikator_uraian_kuantitas;
        this.form.pk_unit_sasaran_indikator_target_kuantitas = data1.indikator_target_kuantitas;
        this.form.pk_unit_sasaran_indikator_satuan_kuantitas = data1.indikator_satuan_kuantitas;
        this.form.pk_unit_sasaran_indikator_uraian_waktu = data1.indikator_uraian_waktu;
        this.form.pk_unit_sasaran_indikator_target_waktu = data1.indikator_target_waktu;
        this.form.pk_unit_sasaran_indikator_satuan_waktu = data1.indikator_satuan_waktu;


        this.form.ku_ra_strategis_indikator  = data2.ra_indikator_id;


        this.form.ra_target_indikator = data2.target_jan + data2.target_feb + 
                                        data2.target_mar + data2.target_apr +
                                        data2.target_mei + data2.target_jun +
                                        data2.target_jul + data2.target_agu +
                                        data2.target_sep + data2.target_okt +
                                        data2.target_nov + data2.target_des ;

        // this.form.ra_target_indikator_bln_ini = data1.xxxx



        const d = new Date();
        const bln = d.getMonth()+1;

        if (bln == 1) {
          this.form.ra_target_indikator_bln_ini = data2.target_jan
        } else if (bln == 2) {
          this.form.ra_target_indikator_bln_ini = data2.target_feb
        } else if (bln == 3) {
          this.form.ra_target_indikator_bln_ini = data2.target_mar
        } else if (bln == 4) {
          this.form.ra_target_indikator_bln_ini = data2.target_apr
        } else if (bln == 5) {
          this.form.ra_target_indikator_bln_ini = data2.target_mei
        } else if (bln == 6) {
          this.form.ra_target_indikator_bln_ini = data2.target_jun
        } else if (bln == 7) {
          this.form.ra_target_indikator_bln_ini = data2.target_jul
        } else if (bln == 8) {
          this.form.ra_target_indikator_bln_ini = data2.target_agu
        } else if (bln == 9) {
          this.form.ra_target_indikator_bln_ini = data2.target_sep
        } else if (bln == 10) {
          this.form.ra_target_indikator_bln_ini = data2.target_okt
        } else if (bln == 11) {
          this.form.ra_target_indikator_bln_ini = data2.target_nov
        } else if (bln == 12) {
          this.form.ra_target_indikator_bln_ini = data2.target_des
        } 



        this.getDataWorksheet()

   


    },


    getLamp : function(data){
      fetch(this.$store.state.url.URL_kinerja_worksheet + "getLamp", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              ref: this.form.id,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_lampiran = res_data;
              console.log("AAAAAA");
              console.log(res_data)
              console.log("AAAAAA");
      });
    },


    removeDataLampiran : function(idnya){
      fetch(this.$store.state.url.URL_kinerja_worksheet + "removeDataLamp", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.lampiran)
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getLamp()
      });

    },

    selectLampiran(data){
      this.lampiran = data
    },



    // ========================================= PERIODE =========================================
   

    // ========================================= PERIODE =========================================



    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },

        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx;
        },
        
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getData();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getData();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getData();
        },

        indicatorColor : function(data){
          if (data == 0) {
            return {
              bg : `bg-orange-2`,
              status : false
            }  
          }
          else if(data == 1){
            return {
              bg : `bg-blue-1`,
              status : false
            }
          }
          else if (data == 2){
            return {
              bg : `bg-red-2`,
              status : true
            }
          }

        },

        sumery : function(){

        },
        convertFormatDate : function(data){

          const MyDate = new Date(data)
          const date  = MyDate.getFullYear() + '-'
                      + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-'
                      + ('0' + MyDate.getDate()).slice(-2);
          return date

        },

        ReConvertFormatDate : async function(data){
          const d = new Date(data)
          this.form.tahun = d.getFullYear();
          this.form.bulan = d.getMonth() + 1;
          this.form.hari = d.getDate();

        },


    // ====================================== PAGINATE ====================================







  },

  mounted () {

    // this.$store.commit("listJeniskategorilokasi");
    // this.$store.commit("getStorage");

    if(this.$q.platform.is.mobile){
      this.marginFilter='q-x-sm'
    }
    else{
      this.marginFilter=''
    }

    const d = new Date();
    this.form.tahun = d.getFullYear();
    this.form.bulan = d.getMonth() + 1;
    this.form.hari = d.getDate();



    this.form.tglFull = this.convertFormatDate(d)

    // console.log(this.form.hari);

    var get_profile = JSON.parse(localStorage.profile);
    this.form.sub_unit_kerja = get_profile.profile.unit_kerja;
    this.form.nip = get_profile.profile.nip;
    this.form.delegasi = get_profile.profile.jabatan;

    UMUM.postListPeriode(this.form.nip)
    // console.log(get_profile)
    // console.log(this.form.sub_unit_kerja)
    this.getData();

  },
}
